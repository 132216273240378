import React from "react";
import hero from "./../assets/png/hero.png";
import { heroMediaLink, mediaLink } from "../helper/Helper";
function Hero() {
  return (
    <>
      <div
        id="Gallery"
        className="max-w-[1700px] px-4 sm:px-6 lg:px-8 xl:px-10  w-full mx-auto my-10 lg:pt-10"
      >
        <div className="flex flex-col justify-center text-center">
          <div className="max-w-[400px] sm:max-w-[500px] lg:max-w-[600px] xl:max-w-[725px] mx-auto ">
            <img
              src={hero}
              alt="img"
              className="w-full -ml-10 sm:-ml-14 md:-ml-20"
            />
          </div>
          <h1 className="heading uppercase text-cream text-6xl sm:text-8xl md:text-9xl xl:text-[150px] 2xl:text-[200px] text-center -mt-5 lg:-mt-16">
            SUI NEIRO
          </h1>
          <p className="text-base sm:text-lg md:text-xl lg:text-2xl xl:text-4xl 2xl:text-[40px] stoke-3 text-white md:mt-4">
            Are you ready to take over?
          </p>
          <div className="flex justify-center gap-3 md:gap-5 xl:gap-6 mt-3 sm:mt-6  lg:mt-12 ">
            <a href="https://hop.ag/swap/SUI-NEIRO" target="_blank">
              <button className="text-white  px-5 md:px-7 lg:px-8 xl:px-10 bg-secondary  border-[1.5px] border-black rounded-md shadow-box  h-10  md:h-12 xl:h-14  transition-all duration-300 ease-in-out   group hover:shadow-custom text-base sm:text-lg lg:text-2xl xl:text-[28px]">
                BUY $NEIRO
              </button>
            </a>
            {heroMediaLink.map((item, index) => (
              <a
                key={index}
                href={item.url}
                target="_blank"
                className=" bg-white border  rounded border-black shadow-box h-10 w-10  md:w-12 md:h-12 xl:h-14 xl:w-14 flex flex-col justify-center items-center transition-all duration-300 ease-in-out   group hover:shadow-custom   "
              >
                {item.icon}
              </a>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Hero;
