import React from "react";
import { navLinks } from "../helper/Helper";
import { CrossIcon } from "../helper/Icon";

const Sidebar = ({ toggleOpen }) => {
  return (
    <>
      <div className="h-screen w-screen lg:hidden flex items-center justify-center flex-col relative">
        <button className="absolute top-10 right-4" onClick={toggleOpen}>
          <CrossIcon />
        </button>

        <div className=" gap-6 flex flex-col  justify-center">
          {navLinks.map((item, index) => (
            <a
              onClick={toggleOpen}
              key={index}
              href={item.url}
              className=" text-base font-super text-white transition-all duration-300 ease-in-out hover:text-black text-center"
            >
              {item.title}
            </a>
          ))}
        </div>
      </div>
    </>
  );
};

export default Sidebar;
