import React from "react";

function Tokenomics() {
  return (
    <>
      <div
        id="Tokenomics"
        className="max-w-[1700px]  px-4  md:px-6 lg:px-8  xl:px-10  w-full mx-auto mt-32 md:mt-20"
      >
        <div className="flex flex-col ">
          <h2 className="subheading text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl 2xl:text-[100px] text-white text-center">
            Tokenomics
          </h2>
          <div className="flex flex-col sm:flex-row gap-6 justify-center sm:justify-between  max-w-[833px] mx-auto w-full mt-8 md:mt-14 lg:mt-20 xl:mt-16 relative">
            <div className="absolute w-[200px] sm:w-[250px]  xl:w-[388px] h-[220px] sm:h-[270px] xl:h-[308px] token-side left-[0px] sm:left-[10px] lg:left-[-90px] xl:left-[-250px] top-[-260px] sm:top-[-260px] lg:top-[-200px] flex flex-col justify-center items-center">
              <div className=" rotate-[-38.697deg] text-center">
                <p className="text-yellow  text-base para-outline uppercase ">
                  Total Supply
                </p>{" "}
                <p className="text-white  text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl para-outline uppercase ">
                  10 Billion
                </p>
              </div>
            </div>
            <div className=" sm:max-w-[216px] text-center w-full ">
              <p className="text-lg md:text-xl lg:text-2xl text-white uppercase">
                Buy/Sell Tax
              </p>
              <p className="text-yellow para-outline text-xl md:text-2xl lg:text-3xl xl:text-4xl">
                0%
              </p>
            </div>{" "}
            <div className=" sm:max-w-[216px] text-center w-full">
              <p className="text-lg md:text-xl lg:text-2xl text-white uppercase">
                Ticker
              </p>
              <p className="text-yellow para-outline text-xl md:text-2xl lg:text-3xl xl:text-4xl">
                $NEIRO
              </p>
            </div>{" "}
            <div className=" sm:max-w-[216px] text-center w-full">
              <p className="text-lg md:text-xl lg:text-2xl text-white uppercase">
                Ownership
              </p>
              <p className="text-yellow para-outline text-xl md:text-2xl lg:text-3xl xl:text-4xl">
                Revoked
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Tokenomics;
