import React, { useEffect, useState } from "react";

import Sidebar from "./Sidebar";
import { mediaLink, navLinks } from "../helper/Helper";
import { HamburgerIcon } from "../helper/Icon";
function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const body = document.body;
    const html = document.documentElement;
    if (isOpen) {
      body.style.overflow = "hidden";
      html.style.overflow = "hidden";
    } else {
      body.style.overflow = "auto";
      html.style.overflow = "auto";
    }
    return () => {
      body.style.overflow = "auto";
      html.style.overflow = "auto";
    };
  }, [isOpen]);
  return (
    <>
      <div className="py-5 md:py-7 xl:py-8 ">
        <div className="max-w-[1700px] px-4 sm:px-6 lg:px-8 xl:px-10  w-full mx-auto">
          <div className="bg-primary border md:border-2 border-black rounded-2xl shadow-custom p-2 md:p-3  xl:p-4 flex flex-wrap sm:flex-row justify-between items-center gap-4">
            <div className=" ">
              <a href="#">
                <span className="text-white text-base md:text-xl lg:text-3xl xl:text-4xl 2xl:text-[41px]  uppercase logo ">
                  $NEIRO
                </span>
              </a>
            </div>
            <div className="gap-4 xl:gap-5   flex-row  justify-center hidden md:flex xl:pl-32">
              {navLinks.map((item, index) => (
                <a
                  key={index}
                  href={item.url}
                  className="text-sm md:text-base lg:text-xl xl:text-[22px]  text-[#373737] transition-all duration-300 ease-in-out hover:text-secondary uppercase"
                >
                  {item.title}
                </a>
              ))}
            </div>
            <div className="flex items-center gap-2 lg:gap-3 xl:gap-5   ">
              {mediaLink.map((item, index) => (
                <a
                  key={index}
                  href={item.url}
                  target="_blank"
                  className=" bg-secondary border  rounded border-black shadow-box h-10 w-10  md:w-12 md:h-12 xl:h-14 xl:w-14 flex flex-col justify-center items-center transition-all duration-300 ease-in-out   group hover:shadow-custom "
                >
                  {item.icon}
                </a>
              ))}

              <a href="https://hop.ag/swap/SUI-NEIRO" target="_blank">
                <button className="text-white px-3  text-sm sm:text-base sm:px-5 md:px-7 lg:px-8 bg-secondary  border-[1.5px] border-black rounded-md shadow-box  h-10  md:h-12 xl:h-14  transition-all duration-300 ease-in-out   group hover:shadow-custom">
                  BUY $NEIRO
                </button>
              </a>
              <button className="md:hidden" onClick={toggleOpen}>
                <HamburgerIcon />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`fixed top-0 left-0 w-full h-full bg-secondary md:bg-transparent transform ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out z-50`}
      >
        <Sidebar toggleOpen={toggleOpen} />
      </div>
    </>
  );
}

export default Navbar;
