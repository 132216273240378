import React from "react";
import logo from "./../assets/png/footer-logo.png";
function Footer() {
  return (
    <>
      <div className="mb-10 xl:mb-20 -mt-20  2xl:-mt-10">
        <div className="flex flex-col justify-center">
          <a
            href="#"
            className="max-w-[120px] xl:max-w-[169px] w-full mx-auto relative z-20"
          >
            <img src={logo} alt="logo" className="w-full" />
          </a>
          <a
            href="#"
            className="heading  text-5xl lg:text-6xl xl:text-7xl 2xl:text-[100px] text-white text-center uppercase -mt-3 2xl-mt-5"
          >
            $NEIRO
          </a>
        </div>
      </div>
    </>
  );
}

export default Footer;
