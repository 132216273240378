import {
  MetaIcon,
  TeligramBlackIcon,
  TeligramIcon,
  TwitterBlackIcon,
  TwitterIcon,
} from "./Icon";

export const navLinks = [
  { title: "About Us", url: "#AboutUs" },
  { title: "How To Buy", url: "#HowToBuy" },
  { title: "Tokenomics", url: "#Tokenomics" },
];

export const mediaLink = [
  { icon: <TwitterIcon />, url: "https://x.com/NeiroOnSui" },
  { icon: <TeligramIcon />, url: "https://t.me/SuiNeiro" },
];
export const heroMediaLink = [
  { icon: <TwitterBlackIcon />, url: "https://x.com/NeiroOnSui" },
  {
    icon: <MetaIcon />,
    url: "https://dexscreener.com/sui/0x5fcc93cc07d57f0fd66b9440a1dacdd633445f42e96adb80118b40e5698fe123::neiro::NEIRO",
  },
  { icon: <TeligramBlackIcon />, url: "https://t.me/SuiNeiro" },
];
export const heroMediaLink2 = [
  { icon: <TeligramBlackIcon />, url: "https://t.me/SuiNeiro" },
  {
    icon: <MetaIcon />,
    url: "https://dexscreener.com/sui/0x5fcc93cc07d57f0fd66b9440a1dacdd633445f42e96adb80118b40e5698fe123::neiro::NEIRO",
  },
  { icon: <TwitterBlackIcon />, url: "https://x.com/NeiroOnSui" },
];
