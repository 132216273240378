import React from "react";

function About() {
  return (
    <>
      <div
        id="AboutUs"
        className="max-w-[1700px]  xl:px-10  w-full mx-auto -my-48  sm:-my-40 lg:-my-10  "
      >
        <div className="flex flex-col justify-center items-center text-center about-bg w-full min-h-[900px] px-4 sm:px-6 lg:px-8">
          <div className="2xl:max-w-[950px] xl:max-w-[850px] lg:max-w-[750px] md:max-w-[550px] w-full mx-auto">
            <h2 className="subheading text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl 2xl:text-[100px] text-white">
              About $NEIRO
            </h2>
            <p className="font-baloo text-base sm:text-lg lg:text-xl 2xl:text-2xl para-outline text-white text-center leading-[140%] sm:leading-[180%] capitalize mt-2 md:mt-4 xl:mt-6">
              SuiNeiro is a fun and community-driven memecoin launched on the
              Sui Blockchain. Our mission is simple: to bring fun and creativity
              to the crypto space. With SuiNeiro, you get fast transactions, low
              fees, and a ton of memes. Join us as $NEIRO wakes up and we shake
              up the crypto world with humor and innovation!
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
