import React from "react";

function HowToBuy() {
  return (
    <>
      <div
        id="HowToBuy"
        className="max-w-[1700px]  sm:px-6 lg:px-8  xl:px-10  w-full mx-auto "
      >
        <div className="flex flex-col  ">
          <h2 className="subheading text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl 2xl:text-[100px] text-white text-center">
            How To Buy
          </h2>

          <div className="flex flex-wrap xl:flex-nowrap   my-10">
            <div className="w-full md:w-1/2 xl:w-1/3 b-1 card-bg min-h-[350px] md:min-h-[400px] flex flex-col justify-center items-center">
              <div className="max-w-[271px] mx-auto rotate-[-17.9deg]">
                <h4 className="text-white  text-lg lg:text-xl 2xl:text-2xl para-outline">
                  Choose Your Wallet
                </h4>
                <p className="text-sm lg:text-base text-black font-baloo-bhai leading-[140%] mt-2">
                  To buy $NEIRO, you need a wallet that supports Sui Network.
                  You can use Sui Wallet or Suiet. Choose yours and join the
                  fun!
                </p>
              </div>
            </div>
            <div className="-mt-20 md:mt-0 w-full md:w-1/2 xl:w-1/3 b-2 card-bg min-h-[350px] md:min-h-[400px] flex flex-col justify-center items-center">
              <div className="max-w-[271px] mx-auto rotate-[-17.9deg]">
                <h4 className="text-white  text-lg lg:text-xl 2xl:text-2xl para-outline">
                  Get Some $SUI
                </h4>
                <p className="text-sm lg:text-base text-black font-baloo-bhai leading-[140%] mt-2">
                  Deposit $SUI to your wallet using a crypto exchange of your
                  choice.
                </p>
              </div>
            </div>{" "}
            <div className="-mt-20 xl:mt-0  w-full md:w-1/2 xl:w-1/3 b-3 card-bg min-h-[350px] md:min-h-[400px] flex flex-col justify-center items-center">
              <div className="max-w-[271px] mx-auto rotate-[-17.9deg]">
                <h4 className="text-white  text-lg lg:text-xl 2xl:text-2xl para-outline">
                  Swap $SUI for $NEIRO
                </h4>
                <p className="text-sm lg:text-base text-black font-baloo-bhai leading-[140%] mt-2">
                  Using your $SUI, you can swap it for $NEIRO using and you’re
                  set.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HowToBuy;
