import logo from "./logo.svg";
import "./App.css";
import Navbar from "./components/common/Navbar";
import Hero from "./components/common/Hero";
import About from "./components/common/About";
import HowToBuy from "./components/common/HowToBuy";
import Tokenomics from "./components/common/Tokenomics";
import Join from "./components/common/Join";
import Footer from "./components/common/Footer";

function App() {
  return (
    <>
      <Navbar />
      <Hero />
      <About />
      <HowToBuy />
      <Tokenomics />
      <Join />
      <Footer />
    </>
  );
}

export default App;
