import React from "react";
import { heroMediaLink2 } from "../helper/Helper";
import joinImg from "./../assets/png/join.png";
function Join() {
  return (
    <>
      <div className="max-w-[1700px]  md:px-6 lg:px-8  xl:px-10  w-full mx-auto    mt-36 ">
        <div className="flex flex-col justify-center items-center text-center about-bg w-full min-h-[900px] px-4 sm:px-6 lg:px-8 -my-48  sm:-my-40 lg:-my-10">
          <div className="2xl:max-w-[1010px] xl:max-w-[850px] lg:max-w-[750px] md:max-w-[550px] w-full mx-auto relative flex flex-col justify-center items-center z-20">
            <div
              className="absolute top-[-280px] md:top-[-260px] lg:top-[-380px]   
               xl:top-[-360px]  min-[1320px]:top-[-380px]   2xl:top-[-380px] min-[1670px]:top-[-405px]  max-w-[200px] lg:max-w-[380px] z-[-1] "
            >
              <img src={joinImg} alt="join img" className="w-full" />
            </div>
            <h2 className="subheading text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl 2xl:text-[100px] text-white">
              Join Our Community
            </h2>
            <p className="font-baloo text-base sm:text-lg lg:text-xl 2xl:text-2xl para-outline text-white text-center leading-[140%] sm:leading-[180%] capitalize mt-2 md:mt-4 xl:mt-6 max-w-[935px] mx-auto">
              Join the community to take over the boring world of crypto with
              memes!
            </p>{" "}
            <div className="flex justify-center gap-3 md:gap-5 xl:gap-6 mt-3 sm:mt-4  xl:mt-12 ">
              <a href="https://hop.ag/swap/SUI-NEIRO" target="_blank">
                <button className="text-white  px-5 md:px-7 lg:px-8 xl:px-10 bg-secondary  border-[1.5px] border-black rounded-md shadow-box  h-10  md:h-12 xl:h-14  transition-all duration-300 ease-in-out   group hover:shadow-custom text-base sm:text-lg lg:text-2xl xl:text-[28px]">
                  BUY $NEIRO
                </button>
              </a>
              {heroMediaLink2.map((item, index) => (
                <a
                  key={index}
                  href={item.url}
                  target="_blank"
                  className=" bg-white border  rounded border-black shadow-box h-10 w-10  md:w-12 md:h-12 xl:h-14 xl:w-14 flex flex-col justify-center items-center transition-all duration-300 ease-in-out   group hover:shadow-custom   "
                >
                  {item.icon}
                </a>
              ))}
            </div>
          </div>
        </div>{" "}
      </div>
    </>
  );
}

export default Join;
